import { useEffect } from "react"
import Dashboard from "../../Dashboard/Dashboard"
import { getUserData } from "../../StorageMechanism/storageMechanism"
import { useNavigate } from "react-router-dom"

const AdminDashboard = () => {
    const user = getUserData();

    const navigate = useNavigate();
    
    useEffect(() => {
        if (user) {
            if (user.role === 'STAFF') {
                navigate('/staff/dashboard');
            } else if (user.role === 'CUSTOMER') {
                navigate('/user/dashboard');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Dashboard userType={user?.role} />
    )
}

export default AdminDashboard