// Libraries
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

// Components
import domain from '../../domain/domain';
import EditModal from '../../SweetPopup/sweetPopup';
import showAlert from '../../SweetAlert/sweetalert';
import SweetLoading from '../../SweetLoading/SweetLoading';
import FailureComponent from '../../FailureComponent/failureComponent'

// Assets
import noClient from '../../Assets/no-customers.png'
import { BiSearch } from 'react-icons/bi';
import { MdFilterList } from 'react-icons/md';

// Styled Components
import {
    SearchButton,
    ClientsHeaderContainer,
    Container,
    ExecuteButton,
    H1,
    SearchBar,
    SearchBarContainer,
    StaffListContainer,
    Table,
    TableContainer,
    Td,
    Th,
    NoClientContainer,
    FilterSelect
} from './styledComponents';
import ClientTable from './clientsTable';
import { getToken, getUserData } from '../../StorageMechanism/storageMechanism';
import { ViewButton } from '../../Clients/StyledComponents';

const apiStatusConstants = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
};

const dataOrder = [
    'Scheduling',
    'TaxInterview',
    'Documents',
    'TaxPreparation',
    'Review',
    'Payments',
    'ClientReview',
    'Filing',
];

const Staff = () => {

    const [staffList, setStaff] = useState([]);
    const [selectedAction, setSelectedAction] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [profileId, setProfileId] = useState(19);
    const [filteredStaff, setFilteredStaff] = useState([]);
    const [assignedClients, setAssignedClients] = useState([])
    const [viewAssignedClients, setViewAssignedClients] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState({})
    const [searchTerm, setSearchTerm] = useState('');
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial)
    const [selectedFilter, setFilterType] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const token = getToken();
    const user = getUserData();

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const onSearch = () => {
        if (searchTerm) {
            const filteredData = staffList.filter((staff) =>
                staff?.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                staff?.contact_number?.includes(searchTerm) ||
                staff?.email_address?.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredStaff(filteredData);
        } else {
            setFilteredStaff(staffList);
        }
    };


    const fetchData = async () => {
        setApiStatus(apiStatusConstants.inProgress)
        try {
            const staffResponse = await axios.get(`${domain.domain}/user/staff`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setFilteredStaff(staffResponse.data)
            setStaff(staffResponse.data);
            setApiStatus(apiStatusConstants.success)
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                if (error?.response?.data?.error) {
                    showAlert({
                        title: 'Error',
                        text: `${error.response.data.error}`,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                    setApiStatus(apiStatusConstants.success);
                } else {
                    setApiStatus(apiStatusConstants.failure);
                    setErrorMsg(error);
                }
            }
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            if (user.role === 'STAFF') {
                navigate('/staff/dashboard')
            } else if (user.role === 'CUSTOMER') {
                navigate('/user/dashboard')
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditClick = () => {
        setIsEditModalOpen(!isEditModalOpen);
        fetchData();
    };

    const handleEditModalClose = () => {
        setIsEditModalOpen(false);
    };

    const onDeleteStaff = async (id) => {
        setApiStatus(apiStatusConstants.inProgress);
        try {
            await axios.delete(`${domain.domain}/user/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setApiStatus(apiStatusConstants.success);
            fetchData();

            showAlert({
                title: 'Staff Deleted Successfully!',
                text: 'The staff member has been deleted successfully.',
                icon: 'success',
                confirmButtonText: 'OK',
            });
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    const actionOptions = [
        { value: 'edit', label: 'Edit / View' },
        { value: 'hold', label: 'Hold' },
        { value: 'delete', label: 'Delete' },
    ];

    const handleActionChange = (selectedOption) => {
        setSelectedAction(selectedOption);
    };

    const handleExecuteAction = (staffId) => {
        if (selectedAction) {
            switch (selectedAction.value) {
                case 'edit':
                    handleEditClick();
                    setProfileId(staffId);
                    break;
                case 'hold':
                    // Implement hold action
                    break;
                case 'delete':
                    onDeleteStaff(staffId);
                    setProfileId(staffId);
                    break;
                default:
                    break;
            }
        }
        setSelectedAction()
    };

    const getAssignedClients = async (id) => {
        const selectedStaffMember = staffList.filter((staff) => staff.user_id === id)
        setSelectedStaff(selectedStaffMember[0])
        setApiStatus(apiStatusConstants.inProgress)
        try {
            const assignmentsResponse = await axios.get(`${domain.domain}/user/staff-clients/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setApiStatus(apiStatusConstants.success)
            setAssignedClients(assignmentsResponse.data);
            setViewAssignedClients(!viewAssignedClients);
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    const [team, setTeam] = useState('')

    const handleTeamChange = (team) => {
        setTeam(team.value)
    }

    const handleStaffTeamUpdate = async (id) => {
        const data = {
            staff_team: team,
            user: user
        };
        try {
            setApiStatus(apiStatusConstants.inProgress);

            const response = await axios.put(
                `${domain.domain}/user/add/staff-team/${id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            if (response) {
                setApiStatus(apiStatusConstants.success); // Set success status if the request was successful
                showAlert({
                    title: 'Staff Team Updated Successfully!',
                    text: 'The staff team has been successfully updated.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                });
            }
            fetchData()
            setTeam()
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
            setTeam()
        }
    };

    const handleFilterChange = (filterType) => {
        setFilterType(filterType);

        let filteredData = [];

        if (filterType === 'assigned') {
            filteredData = staffList.filter((staff) => staff.staff_team !== '' && staff.staff_team !== null);
        } else if (filterType === 'unassigned') {
            filteredData = staffList.filter((staff) => staff.staff_team === '' || staff.staff_team === null);
        } else {
            filteredData = staffList;
        }
        setFilteredStaff(filteredData);
    };


    const renderSuccess = () => {
        return (
            <StaffListContainer >
                <H1>Staff</H1>
                {staffList.length > 0 ?
                    <TableContainer style={{ paddingBottom: '0' }} className='shadow'>
                        <ClientsHeaderContainer>
                            <SearchBarContainer>
                                <SearchBar
                                    type="text"
                                    placeholder="Search staff"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                <SearchButton onClick={onSearch}><BiSearch size={25} /></SearchButton>
                            </SearchBarContainer>
                            <div>
                                <label htmlFor="filterDropdown">
                                    <MdFilterList size={20} />
                                </label>
                                <FilterSelect
                                    id="filterDropdown"
                                    value={selectedFilter}
                                    onChange={(e) => handleFilterChange(e.target.value)}
                                >
                                    <option value="">All Staff</option>
                                    <option value="assigned">Assigned Staff</option>
                                    <option value="unassigned">Unassigned Staff</option>
                                </FilterSelect>
                            </div>
                        </ClientsHeaderContainer>


                        <Container style={{ paddingBottom: '100px' }}>
                            {filteredStaff.length > 0 ?
                                <Table>
                                    <thead>
                                        <tr>
                                            <Th>ID</Th>
                                            <Th>Name</Th>
                                            <Th>Email</Th>
                                            <Th>Team</Th>
                                            <Th>Select Team</Th>
                                            <Th>Assigned Clients</Th>
                                            <Th>Profile Actions</Th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredStaff.map((staff) => (
                                            <tr key={staff.user_id}>
                                                <Td>{staff.user_id}</Td>
                                                <Td>{staff.first_name}</Td>
                                                <Td>{staff.email_address}</Td>
                                                <Td>{staff.staff_team}</Td>
                                                <Td>
                                                    <div className='d-flex align-items-center justify-content-center' style={{ gap: '.8rem' }}>
                                                        <Select
                                                            options={dataOrder.map((team) => ({
                                                                value: team,
                                                                label: team,
                                                                data: team,
                                                            }))}
                                                            onChange={handleTeamChange}
                                                            placeholder="Select Team"
                                                            required
                                                        />
                                                        <ExecuteButton
                                                            onClick={() => handleStaffTeamUpdate(staff.user_id)}
                                                            disabled={!team}
                                                        >
                                                            Add
                                                        </ExecuteButton>
                                                    </div>
                                                </Td>
                                                <Td>
                                                    <ViewButton
                                                        onClick={() => getAssignedClients(staff.user_id)}
                                                    >
                                                        View
                                                    </ViewButton>
                                                </Td>
                                                <Td>
                                                    <div className='d-flex align-items-center justify-content-center' style={{ gap: '.8rem' }}>
                                                        <Select
                                                            options={actionOptions}
                                                            onChange={handleActionChange}
                                                            placeholder="Select Action"
                                                        />
                                                        <ExecuteButton
                                                            onClick={() => handleExecuteAction(staff.user_id)}
                                                        >
                                                            Execute
                                                        </ExecuteButton>
                                                    </div>
                                                </Td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                :
                                <NoClientContainer>
                                    <img src={noClient} alt='img' className='img-fluid' />
                                    <H1>No Staff Available!</H1>
                                    <p>Oops! It seems there are no staff Added here.</p>
                                </NoClientContainer>}
                        </Container>

                        {viewAssignedClients && selectedStaff && <p className='mt-5'>Staff Member: {selectedStaff.first_name}</p>}
                        {assignedClients && <ClientTable assignedClients={assignedClients} viewAssignedClients={viewAssignedClients} />}
                        {viewAssignedClients && assignedClients.length === 0 && <p>No Clients Assigned</p>}
                    </TableContainer>
                    :
                    <NoClientContainer>
                        <img src={noClient} alt='img' className='img-fluid' />
                        <H1>No Staff Added</H1>
                        <p>Oops! It seems there are no staff Added here.</p>
                    </NoClientContainer>
                }
            </StaffListContainer>
        )
    }

    const renderComponents = () => {
        switch (apiStatus) {
            case apiStatusConstants.failure:
                return <FailureComponent errorMsg={errorMsg} fetchData={fetchData} />
            case apiStatusConstants.inProgress:
                return <SweetLoading />
            case apiStatusConstants.success:
                return renderSuccess()

            default:
                return null
        }
    }

    return (
        <>
            {renderComponents()}

            <EditModal
                isOpen={isEditModalOpen}
                profileId={profileId}
                onRequestClose={handleEditModalClose}
                handleOpenClick={handleEditClick}
                isEditable={true}
            />
        </>
    );
};

export default Staff;
