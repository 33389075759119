import { useNavigate } from 'react-router-dom';
import Documents from '../../Documents/Documents';
import { getUserData } from '../../StorageMechanism/storageMechanism';
import { useEffect } from 'react';

const ClientTaxDocuments = ({clientId}) => {

    const user = getUserData()
    const role = user.role
    const navigate = useNavigate();

    useEffect(() => {
        if (role) {
            if (role === 'STAFF') {
                navigate('/staff/dashboard');
            } else if (role === 'CUSTOMER') {
                navigate('/user/dashboard');
            }
        }
    })
    return (
        <Documents clientId={clientId} userType={'ADMIN'}/>
    )
}

export default ClientTaxDocuments