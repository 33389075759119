import styled from "styled-components";

export const DashboardContainer = styled.div`
    margin-top: 10vh;
    height: 90vh;
    padding:20px;
    overflow-y: auto;
    background-color: var(--main-background);
    width: 100%;
`;

export const Heading = styled.h1`
     color: var(--headings);
    font-weight: bolder;
    font-size: 26px;
    font-family:Arial, Helvetica, sans-serif;

    @media screen and (max-width:768px){
        font-size:20px;
    }
`;

export const CardsContainer = styled.ul`
    list-style-type:none;
    padding:0px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-top:30px;
`

export const SectionCard = styled.div`
  border:1px solid var(--border);
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0px 20px var(--shadow);
  overflow: hidden;
  cursor: pointer;
  width:100%;
  
  &:hover{
    transform: scale(1.06);
    transition: .2s ease-in;
    box-shadow: 0 0px 20px var(--main-background-shade);
  }

  @media (min-width: 768px) {
    flex: 0 0 calc(50% - 20px);
    margin-right: 20px;
  }

  @media (min-width: 1024px) {
    flex: 0 0 calc(33.333% - 20px);
  }
`;

export const DashboardIcon = styled.div`
    padding:10px;
`

export const CardDetailsContainer = styled.div`
    ${'' /* padding:6px; */}
`

export const CardHeading = styled.h1`
     color: var(--headings);
    font-weight: bolder;
    font-size: 20px;
    margin: 10px 0;
    margin-bottom: 20px;
    font-family:Arial, Helvetica, sans-serif;

    @media screen and (max-width:768px){
        font-size:16px;
    }
`;

export const CardDescription = styled.p`
    color: var(--text-light);
    font-size: 16px;
    margin:0;
    @media screen and (max-width:768px){
        font-size:14px;
    }
`;

export const DashboardItem = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  gap:20px;
  padding: 16px;

  .dashboard-icon {
    margin-bottom: 10px;
    color: ${(props) => props.iconColor || '#333'};
  }
  @media screen and (max-width:768px){
    width:100%;
  }
`;

export const DetailsContainer = styled.div`
  margin-top: 20px;
`;

export const ClientsHeaderContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-bottom:20px;
    @media screen and (max-width:512px){
        flex-direction:column;
        align-items:flex-start;
    }
`

export const NoOfClientsAssignmentFilterContainer = styled.div`
    display:flex;
    align-items:center;
    gap:10px;
`

export const Input = styled.input`
    padding:6px;

`