import React from 'react';

const ClientList = ({ clients, handleClientChange }) => {
    return (
        <ul style={{ listStyleType: 'none', padding: '0', margin: '0', border: '1px solid #ccc' }}>
            {clients.map(client => (
                <li key={client.user_id} style={{ padding: '10px', borderBottom: '1px solid #ccc', cursor: 'pointer' }} onClick={() => handleClientChange(client)}>
                    {client.first_name} - ID: {client.unique_id}
                </li>
            ))}
        </ul>
    );
};

export default ClientList;
