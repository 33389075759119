// FailureComponent.jsx
import React from 'react';
import noInternet from '../Assets/no-internet.png';
import notfound from '../Assets/notfound.png';
import server from '../Assets/server.png';
import unknown from '../Assets/unknown.png';
import unauthorized from '../Assets/unauthourized.png';

const FailureComponent = ({ errorMsg, fetchData }) => {
    const onClickTryAgain = () => {
        fetchData();
    };

    const errorInfo = getErrorInfo(errorMsg);

    return (
        <div className='d-flex flex-column align-items-center justify-content-center w-100' style={{ backgroundColor: 'var(--main-background)', paddingTop: '10vh', height: '100vh' }}>
            <img src={errorInfo.image} alt='Error' style={{margin:'0'}} className='img-fluid' />
            <h2 style={{ margin: '0' }}>Error Occurred</h2>
            <p>{errorInfo.message}</p>
            {errorInfo.emailError && <p style={{ margin: '0' }}>{errorInfo.emailError}</p>}
            <button type='button' onClick={onClickTryAgain} style={{ padding: '10px', backgroundColor: 'var(--accent-background)', color: 'var(--background-white)', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                Please try again!
            </button>
        </div>
    );
};

const getErrorInfo = (error) => {
    let errMessage = '';
    let errImage = '';
    let emailError = '';

    if (error?.message === 'Network Error') {
        errMessage = 'Unable to connect to the server. Please check your internet connection.';
        errImage = noInternet;
    } else if (error.response) {
        const { status, data } = error.response;
        switch (status) {
            case 401:
                errMessage = 'Unauthorized. Please log in.';
                errImage = unauthorized;
                break;
            case 403:
                errMessage = 'Unauthorized. Please log in.';
                errImage = unauthorized;
                break;
            case 404:
                errMessage = 'Resource not found.';
                errImage = notfound;
                break;
            case 500:
                errMessage = 'Internal server error. Please try again later.';
                errImage = server;
                break;
            case 422: // Unprocessable Entity - email-related error
                emailError = data.message || 'Email-related error occurred.';
                break;
            default:
                errMessage = `Error ${status}: ${data.message || 'Unknown error'}`;
                errImage = unknown;
        }
    } else {
        errMessage = 'An unexpected error occurred. Please try again later.';
        errImage = unknown;
    }

    return { message: errMessage, image: errImage, emailError };
};

export default FailureComponent;
