import { useNavigate } from "react-router-dom";
import Dashboard from "../../Dashboard/Dashboard"
import { getUserData } from "../../StorageMechanism/storageMechanism"
import { useEffect } from "react";


const StaffDashboard = () => {
    const user = getUserData()
    const navigate = useNavigate();
    const role = user?.role
    useEffect(() => {
        if (user) {
            if (role === 'ADMIN') {
                navigate('/admin/dashboard');
            } else if (role === 'CUSTOMER') {
                navigate('/user/dashboard');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Dashboard userType={user?.role} />
    )
}

export default StaffDashboard