import React from 'react';

const SweetLoading = () => {

    return (

        <div className='d-flex align-items-center justify-content-center w-100' style={{ backgroundColor: `var(--main-background)`, paddingTop: '10vh', height: '100vh' }}>
            <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: '50%' }} className='d-flex align-items-center justify-content-center shadow'>
                <div className="spinner-border" style={{color:`var(--accent-background)`}} role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </div>

    );
};

export default SweetLoading;
