// import { FaCalendarAlt, FaClock, FaFileAlt, FaTasks, FaClipboardList, FaMoneyBillAlt, FaCheck } from 'react-icons/fa';

export const documentType = [
    { value: 'Personal Tax Notes', label: 'Personal Tax Notes' },
    { value: 'Business Tax Notes', label: 'Business Tax Notes' },
    { value: 'Rental Income Tax Notes', label: 'Rental Income Tax Notes' },
    { value: 'SSN Card', label: 'SSN Card' },
    { value: 'ITIN document', label: 'ITIN document' },
    { value: 'Wages W-2, W-2C, Paystubs', label: 'Wages W-2, W-2C, Paystubs' },
    { value: 'Interest income-1099 INT Dividends - 1099 DIV', label: 'Interest income-1099 INT Dividends - 1099 DIV' },
    { value: 'Taxable refunds - 1099 G', label: 'Taxable refunds - 1099 G' },
    { value: 'Charity receipts', label: 'Charity receipts' },
    { value: 'Health care document -1095 A', label: 'Health care document -1095 A' },
    { value: 'Stocks/Capital gains 1099 B', label: 'Stocks/Capital gains 1099 B' },
    { value: 'IRA contributions', label: 'IRA contributions' },
    { value: 'Contract worker -1099 MISC', label: 'Contract worker -1099 MISC' },
    { value: 'Gambling income W-2G', label: 'Gambling income W-2G' },
    { value: 'HAS 1098 HAS', label: 'HAS 1098 HAS' },
    { value: 'Tuition fee 1098 T Student loan Interest 1098 E', label: 'Tuition fee 1098 T Student loan Interest 1098 E' },
    { value: 'Form 1099 K', label: 'Form 1099 K' },
    { value: 'Home mortgage Interest 1098 HMI', label: 'Home mortgage Interest 1098 HMI' },
    { value: 'Social Security benefits 1099 SA', label: 'Social Security benefits 1099 SA' },
    { value: 'Electric vehicle', label: 'Electric vehicle' },
    { value: 'Solar products', label: 'Solar products' },
    { value: 'Medical receipts', label: 'Medical receipts' },
    { value: 'Other Documents', label: 'Other Documents' },
];


// const calculateTotal = (clients, step) => {
//     const total = clients.filter((client) => client.current_step === step)
//     return total.length
// };


// export const colorMapping = {
//     Scheduling: '#42A5F5',
//     TaxInterview: '#FF7043',
//     Documents: '#4CAF50',
//     TaxPreparation: '#FFEB3B',
//     Review: '#9C27B0',
//     Payments: '#EF5350',
//     ClientReview: '#00E676',
//     Filing: '#FFC107',
//     ClientInterview: '#FF9800',
// };

// export const data = {
//     Scheduling: {
//         title: "Scheduling",
//         description: "Schedule appointments and meetings with clients.",
//         total: calculateTotal(allClients, 'Scheduling'),
//         icon: <FaCalendarAlt size={50} />,
//         color: colorMapping["Scheduling"]
//     },
//     TaxInterview: {
//         title: 'Tax Interview',
//         description: 'Conduct tax interviews and gather necessary information.',
//         total: calculateTotal(allClients, 'TaxInterview'),
//         icon: <FaClock size={50} />,
//         color: colorMapping['TaxInterview']
//     },
//     Documents: {
//         title: 'Documents Collection',
//         description: 'Collect and organize required tax documents from clients.',
//         total: calculateTotal(allClients, 'Documents'),
//         icon: <FaFileAlt size={50} />,
//         color: colorMapping.Documents
//     },
//     TaxPreparation: {
//         title: 'Tax Preparation',
//         description: 'Prepare and calculate tax returns for clients.',
//         total: calculateTotal(allClients, 'TaxPreparation'),
//         icon: <FaTasks size={50} />,
//         color: colorMapping['TaxPreparation']
//     },
//     Review: {
//         title: 'Review',
//         description: 'Review and verify tax returns for accuracy.',
//         total: calculateTotal(allClients, 'Review'),
//         icon: <FaCheck size={50} />,
//         color: colorMapping.Review
//     },
//     Payments: {
//         title: 'Payments Processing',
//         description: 'Process and handle client payments related to tax services.',
//         total: calculateTotal(allClients, 'Payments'),
//         icon: <FaMoneyBillAlt size={50} />,
//         color: colorMapping.Payments
//     },
//     ClientReview: {
//         title: 'Client Review',
//         description: 'Conduct client reviews and address any concerns or questions.',
//         total: calculateTotal(allClients, 'ClientReview'),
//         icon: <FaClipboardList size={50} />,
//         color: colorMapping['ClientReview']
//     },
//     Filing: {
//         title: 'Filing',
//         description: 'Submit and file tax returns on behalf of clients.',
//         total: calculateTotal(allClients, 'Filing'),
//         icon: <FaFileAlt size={50} />,
//         color: colorMapping.Filing
//     },
// };
