import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled, { keyframes } from 'styled-components';

const scrollRightToLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const ReviewsContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

const ReviewCard = styled.div`
  min-width: 300px;
  margin: 10px;
  padding: 20px;
  text-align: left;
  font-style:italic;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  animation: ${scrollRightToLeft} ${(props) => props.animationDuration} linear infinite;
`;

const Span = styled.span`
    font-style: italic;
    font-size: 22px;
    color: #777;
    position: absolute;
    bottom: 10px;
    left: 20px;
`

const Header = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;

    
`

const Heading = styled.h1`
    font-style:italic;
    font-weight:bold;
    color:blue;
    @media screen and (max-width:768px){
        font-size:20px;
    }
`

const CloseButton = styled.button`
    border:none;
    color:white;
    font-size:50px;
    height:60px;
    width:60px;
    background:transparent;
    @media screen and (max-width:768px){
        font-size:30px;
    }
`

const PositiveReviewsPopup = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setModalIsOpen(true);
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const reviews = [
        { name: 'John', review: 'UniProFin has made managing my finances a breeze! The intuitive features and detailed insights have greatly simplified my financial planning.' },
        { name: 'Emily', review: 'I love the user-friendly interface of UniProFin. It\'s easy to navigate, and the visuals help me understand my financial data better.' },
        { name: 'David', review: 'The customer support at UniProFin is top-notch. They are responsive, knowledgeable, and go above and beyond to assist with any queries or issues.' },
        { name: 'Sophia', review: 'UniProFin helped me achieve my financial goals. The goal tracking feature motivated me to save more, and I successfully reached my savings target.' },
    ];

    const customStyles = {
        content: {
            height: '40vh',
            margin: 'auto',
            backgroundColor: 'transparent',
            border: 0,
        },
        overlay: {
            backgroundColor: 'rgba(128, 128, 140, 0.75)',
            backdropFilter: 'blur(3px)',
        },
    };

    const animationDuration = reviews.length * 3 + 's'; // Adjust the multiplier as needed

    return (
        <Modal
            isOpen={modalIsOpen}
            contentLabel="Edit Modal"
            style={customStyles}
        >
            <Header>
                <Heading>What people say</Heading>
                <CloseButton onClick={handleCloseModal}>&times;</CloseButton>

            </Header>
            <ReviewsContainer>
                {reviews.map((review, index) => (
                    <ReviewCard key={index} animationDuration={animationDuration}>
                        <p><span>" </span>{review.review} <span>" </span></p>
                        <Span>
                            -- {review.name}
                        </Span>
                    </ReviewCard>
                ))}
            </ReviewsContainer>
        </Modal>
    );
};

export default PositiveReviewsPopup;
