import { Table, Td, Th } from "./styledComponents"

const ClientTable = ({ assignedClients, viewAssignedClients }) => {

    return (
        <>{viewAssignedClients && assignedClients.length > 0 && 
        <Table>
            <thead>
                <tr>
                    <Th>ID</Th>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Phone</Th>
                </tr>
            </thead>
            <tbody>
                {assignedClients.map((client) => (
                    <tr key={client.user_id} >
                        <Td>{client.user_id}</Td>
                        <Td>{client.first_name}</Td>
                        <Td>{client.email_address}</Td>
                        <Td>{client.contact_number}</Td>
                    </tr>
                ))}
            </tbody>
        </Table>}</>
    )
}

export default ClientTable