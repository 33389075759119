import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken, getUserData } from '../../StorageMechanism/storageMechanism';
import axios from 'axios';

// Components
import EditModal from '../../SweetPopup/sweetPopup';
import SweetLoading from '../../SweetLoading/SweetLoading';
import FailureComponent from '../../FailureComponent/failureComponent';
import ClientFilter from '../../Clients/clientFilter';
import ClientTable from '../../Clients/clientTable';
import domain from '../../domain/domain';

import { ClientListContainer, Heading, NoClientContainer, TableContainer } from './styledComponents';
import noClient from '../../Assets/no-customers.png';
import showAlert from '../../SweetAlert/sweetalert';

const apiStatusConstants = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
};

const AssignedClientList = () => {
    const token = getToken();
    const currentUser = getUserData();
    const userType = currentUser?.role

    const [myClients, setMyClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [profileId, setProfileId] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);

    const navigate = useNavigate();

    const getAllAssignedClients = async () => {
        setApiStatus(apiStatusConstants.inProgress);
        try {
            const assignedClientsResponse = await axios.get(`${domain.domain}/user/staff-clients`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (assignedClientsResponse.status === 200) {
                setApiStatus(apiStatusConstants.success);
                const filteredClients = assignedClientsResponse.data.filter(client => client.staff_id === currentUser.user_id);
                setMyClients(filteredClients);
                setFilteredClients(filteredClients)
            }
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    useEffect(() => {
        if (currentUser) {
            if (currentUser.role === 'ADMIN') {
                navigate('/admin/dashboard')
            } else if (currentUser.role === 'CUSTOMER') {
                navigate('/user/dashboard')
            }
        }
       
        setApiStatus(apiStatusConstants.inProgress);
        const timeoutId = setTimeout(() => {
            getAllAssignedClients();
        },1000)
        return () => clearTimeout(timeoutId)

    // eslint-disable-next-line 
    }, [token, navigate]);

    const handleEditClick = (id) => {
        console.log(id)
        setIsEditModalOpen(!isEditModalOpen);
    };

    const handleEditModalClose = () => {
        setIsEditModalOpen(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };


    const onSearch = () => {
        if (searchTerm) {
            const filteredData = myClients.filter((user) =>
                user?.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user?.contact_number?.includes(searchTerm) ||
                user?.email_address?.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredClients(filteredData);
        } else {
            setFilteredClients(myClients);
        }
    };

    const renderClients = () => {
        return (
            <ClientListContainer>
                <Heading>Clients</Heading>
                {myClients.length > 0 ?
                    <TableContainer className="shadow">
                        <ClientFilter
                            handleSearchChange={handleSearchChange}
                            onSearch={onSearch}
                            searchTerm={searchTerm}
                            userType={userType}
                        />
                        <ClientTable
                            clients={filteredClients}
                            handleEditClick={handleEditClick}
                            setProfileId={setProfileId}
                            userType={userType}
                        />
                    </TableContainer>
                    :
                    <NoClientContainer>
                        <img src={noClient} alt='img' className='img-fluid' />
                        <Heading>No Clients Assigned</Heading>
                        <p>Oops! It seems there are no clients assigned to you.</p>
                    </NoClientContainer>}

                <EditModal
                    isOpen={isEditModalOpen}
                    profileId={profileId}
                    onRequestClose={handleEditModalClose}
                    handleOpenClick={handleEditClick}
                    isEditable={false}
                />
            </ClientListContainer>

        )
    };

    const renderComponents = () => {
        switch (apiStatus) {
            case apiStatusConstants.failure:
                return <FailureComponent errorMsg={errorMsg} fetchData={getAllAssignedClients}/>;
            case apiStatusConstants.inProgress:
                return <SweetLoading />;
            case apiStatusConstants.success:
                return renderClients();
            default:
                return null;
        }
    };

    return (
        renderComponents()
    );
};

export default AssignedClientList;
