// ReferralSection.js

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal'
import { CloseButton, ManyMoreButton } from './styledComponents';
import { Link } from 'react-router-dom';

const ReferralSectionContainer = styled.section`
  padding: 20px;
  background-color:#ffa500;
`;

const Heading = styled.h1`
    outline-color: white;
    color: var(--heading);
`;


// const ReferralCodeContainer = styled.div`
//   margin-bottom: 10px;
// `;

// const DiscountContainer = styled.div`
//   margin-top: 10px;
//   background-color: #e6f7ff;
//   padding: 10px;
//   border: 1px solid #66b2ff;
//   border-radius: 8px;
// `;

// const DiscountPercentage = styled.span`
//   font-weight: bold;
//   color: #0099ff;
// `;

const Header = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;    
`

const ReferralSection = () => {
    // const [isDiscountVisible, setDiscountVisible] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setModalIsOpen(true);
        }, 3000);

        return () => clearTimeout(timeoutId);
    }, []);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    // const shareReferral = () => {
    //     // Assume some logic for sharing the referral, and then show the discount
    //     setDiscountVisible(true);
    // };

    const customStyles = {
        content: {
            height: '60vh',
            padding:"0px",
            margin: 'auto',
            backgroundColor: 'transparent',
            border: 0,
        },
        overlay: {
            backgroundColor: 'rgba(128, 128, 140, 0.75)',
            backdropFilter: 'blur(0px)',
        },
    };



    return (
        <Modal
            isOpen={modalIsOpen}
            contentLabel="Edit Modal"
            style={customStyles}
        >
            <Header>
                <CloseButton onClick={handleCloseModal}>&times;</CloseButton>

            </Header>
            <ReferralSectionContainer>
                <Heading>Refer a Friend and Get Upto 15% Off!</Heading>
                <p>Share your referral code with friends and enjoy tax-saving benefits on your next financial planning or consultation service. Every successful referral not only helps your friends but also earns you exclusive discounts for your upcoming tax-related services.</p>


                {/* <ReferralCodeContainer>
                    <p>Your Referral Code: <span>ABC123</span></p>
                </ReferralCodeContainer> */}

                {/* <button onClick={shareReferral}>Register</button> */}

                <Link to='/accounts/signup'>
                    <ManyMoreButton>
                        Register
                    </ManyMoreButton>
                </Link>

                {/* {isDiscountVisible && (
                    <DiscountContainer>
                        <p>You've earned a <DiscountPercentage>5%</DiscountPercentage> discount!</p>
                    </DiscountContainer>
                )} */}
            </ReferralSectionContainer>
        </Modal>
    );
};

export default ReferralSection;
