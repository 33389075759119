import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import domain from '../../domain/domain';
import showAlert from '../../SweetAlert/sweetalert';
import noDoc from '../../Assets/no-documents.png';


import SweetLoading from '../../SweetLoading/SweetLoading';
import formatDateTime from '../../FormatDateTime/DateTime';
import FailureComponent from '../../FailureComponent/failureComponent';
import { Button, Heading } from '../../Clients/StyledComponents';
import { getToken, getUserData } from '../../StorageMechanism/storageMechanism';
import { Lable } from '../../userComponents/CommentDocument/styledComponents';
import { MdDelete } from 'react-icons/md';
import { handleDownloadClick, renderDocumentThumbnail } from '../../CommonFunctions/commonFunctions';
import { ClientDocumentContainer, ClientTaxDocumentsHeaderContainer, CtaSection, DocumentName, DocumentTable, DocumentTableContainer, H1, NoDocuments, Td, Th } from '../ClientTaxDocuments/styledComponents';
import DocumentTableComponent from '../../Documents/documentTable';


const apiStatusConstants = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
};

const Documents = ({ clientId,userType }) => {
    const user = getUserData();
    const accessToken = getToken();
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
    const [documents, setDocuments] = useState([]);
    const [comments, setComments] = useState([]);
    const [showComments, setShowComments] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState({});
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');

    const navigate = useNavigate();

    const onChangeDocumentStatus = async (id, status) => {
        setApiStatus(apiStatusConstants.inProgress)
        try {
            await axios.put(`${domain.domain}/customer-tax-document/review-status/${id}`, { user_id: user.user_id, review_status: status }, {
                headers: { Authorization: `Bearer ${accessToken}` }
            });
            showAlert({
                title: 'Status Updated Successfully!',
                text: '',
                icon: 'success',
                confirmButtonText: 'Ok',
            });
            setApiStatus(apiStatusConstants.success)
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };


    const handleGetComments = async (document) => {
        setApiStatus(apiStatusConstants.inProgress)
        try {
            const response = await axios.get(`${domain.domain}/customer-tax-comment/get-comments/${document.document_id}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            });
            if (response.status === 200) {
                setComments(response.data);
                setShowComments(!showComments)
                setSelectedDocument(document)
                setApiStatus(apiStatusConstants.success)
            } else {
                setApiStatus(apiStatusConstants.failure)
            }

        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    const getDocumentsById = async () => {
        if (clientId) {
            try {
                const response = await axios.get(`${domain.domain}/customer-tax-document/get-assigned-client-documents/${clientId}`, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                console.log(response);
                if (response.status === 200) {
                    setFilteredDocuments(response.data);
                    setDocuments(response.data)
                    setApiStatus(apiStatusConstants.success);
                } else {
                    setFilteredDocuments(documents);
                }
            } catch (error) {
                if (error.response.data.error) {
                    showAlert({
                        title: 'Error',
                        text: `${error.response.data.error}`,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                    setApiStatus(apiStatusConstants.success);
                } else {
                    setApiStatus(apiStatusConstants.failure);
                    setErrorMsg(error);
                }
            }
        }
    };

    useEffect(() => {
        setApiStatus(apiStatusConstants.inProgress);
        const timeoutId = setTimeout(() => {
            if (clientId) {
                getDocumentsById();
            }
        }, 500);

        return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, clientId,]);


    const onDeleteDocument = async (id) => {
        setApiStatus(apiStatusConstants.inProgress)
        try {
            const response = await axios.delete(`${domain.domain}/customer-tax-document/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            if (response) {
                setApiStatus(apiStatusConstants.success)
                getDocumentsById()
                showAlert({
                    title: 'Document deleted Successfully!',
                    text: '',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                });
            }
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    }

    const onDeleteDocumentComment = async (id) => {
        setApiStatus(apiStatusConstants.inProgress)
        try {
            await axios.delete(`${domain.domain}/customer-tax-comment/${id}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            });
            setApiStatus(apiStatusConstants.success)
            handleGetComments(selectedDocument);
            showAlert({ title: 'Comment Deleted Successfully!', icon: 'success', confirmButtonText: 'Ok' });
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    const successRender = () => {
        return (
            <ClientDocumentContainer >
                <Heading>Tax Documents</Heading>
                {documents.length > 0 ?
                    <CtaSection className="shadow">
                        <DocumentTableContainer >
                            <ClientTaxDocumentsHeaderContainer>
                                <Heading>Uploaded Documents</Heading>
                            </ClientTaxDocumentsHeaderContainer>
                            {filteredDocuments.length > 0 ? (
                                <DocumentTableComponent userType={userType} handleGetComments={handleGetComments} onDeleteDocument={onDeleteDocument} onChangeDocumentStatus={onChangeDocumentStatus} documents={documents} formatDateTime={formatDateTime} handleDownloadClick={handleDownloadClick} renderDocumentThumbnail={renderDocumentThumbnail} />
                            ) : (
                                <div>No Documents uploaded by this client</div>
                            )}
                            {showComments && (
                                <DocumentTableContainer className='mt-4'>
                                    <Lable><strong>Comments for Document:</strong> <strong style={{ color: `var(--accent-background)` }}> {selectedDocument.document_name}</strong> </Lable>
                                    {comments.length > 0 ? <DocumentTable>
                                        <thead>
                                            <tr>
                                                <Th>Document</Th>
                                                <Th>Comment</Th>
                                                <Th>Updated On</Th>
                                                <Th>Delete</Th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {comments.map((comment) => (
                                                <tr key={comment.comment_id}>
                                                    <Td>
                                                        <div className='d-flex flex-column'> <a
                                                            href={`${domain.domain}/customer-tax-document/download/${selectedDocument.document_id}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            download
                                                            onClick={(e) => handleDownloadClick(selectedDocument)}
                                                        >
                                                            {renderDocumentThumbnail(selectedDocument)}
                                                        </a>
                                                            <DocumentName>{selectedDocument.document_path.split('-')[1]}</DocumentName>
                                                        </div>
                                                    </Td>
                                                    <Td>{comment.comment}</Td>
                                                    <Td>{formatDateTime(comment.updated_on)}</Td>
                                                    <Td>
                                                        <Button title="delete document" onClick={() => onDeleteDocumentComment(comment.comment_id)}>
                                                            <MdDelete size={25} className="text-danger" />
                                                        </Button>
                                                    </Td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </DocumentTable> : <p className="text-danger">No Comments to this document.</p>}
                                </DocumentTableContainer>
                            )}
                        </DocumentTableContainer>
                    </CtaSection> : (
                        <NoDocuments>
                            <img src={noDoc} alt='no-doc' className='img-fluid' />
                            <H1>No Documents!</H1>
                            <label>No documents have been uploaded by the client. Please upload relevant documents to proceed.</label>
                        </NoDocuments>
                    )}
            </ClientDocumentContainer>
        )
    }

    const onRenderComponents = () => {
        switch (apiStatus) {
            case apiStatusConstants.inProgress:
                return <SweetLoading />
            case apiStatusConstants.success:
                return successRender()
            case apiStatusConstants.failure:
                return <FailureComponent errorMsg={errorMsg} fetchData={getDocumentsById} />
            default:
                return null
        }
    }

    return (
        onRenderComponents()
    );
};

export default Documents;
