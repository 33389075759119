
import React, { useEffect, useState } from "react";
import axios from "axios";
import { getToken, getUserData } from "../StorageMechanism/storageMechanism";
import domain from "../domain/domain";
import { FaCalendarAlt, FaClock, FaFileAlt, FaTasks, FaClipboardList, FaMoneyBillAlt, FaCheck } from 'react-icons/fa';
import SweetLoading from "../SweetLoading/SweetLoading";
import FailureComponent from "../FailureComponent/failureComponent";
import ClientTable from "../AdminComponents/AdminDashboard/clientTable";
import showAlert from "../SweetAlert/sweetalert";
import SecretCode from "../staffComponents/SecretCodePopup/secretCodePopup";
import {
    CardDescription,
    CardDetailsContainer,
    CardHeading,
    CardsContainer,
    ClientsHeaderContainer,
    DashboardContainer,
    DashboardIcon,
    DashboardItem,
    Heading,
    Input,
    NoOfClientsAssignmentFilterContainer,
    SectionCard
} from "./StyledComponents";

const colorMapping = {
    Scheduling: '#42A5F5',
    TaxInterview: '#FF7043',
    Documents: '#4CAF50',
    TaxPreparation: '#FFEB3B',
    Review: '#9C27B0',
    Payments: '#EF5350',
    ClientReview: '#00E676',
    Filing: '#FFC107',
    ClientInterview: '#FF9800',
};

const apiStatusConstants = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
};

const dataOrder = [
    'Scheduling',
    'TaxInterview',
    'Documents',
    'TaxPreparation',
    'Review',
    'Payments',
    'ClientReview',
    'Filing',
];

const staffDataOrder = [
    'Scheduling',
    'TaxInterview',
    'Documents',
    'TaxPreparation',
    'Review',
    'Payments',
    'Filing',
];

const Dashboard = ({ userType }) => {
    const [allClients, setAllClients] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [clients, setClients] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
    const [availableSteps, setAvailableSteps] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [profileId, setProfileId] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [showSecretCodePopup, setShowSecretCodePopup] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [staffList, setStaff] = useState([]);
    const [selectedRange, setSelectedRange] = useState('');

    const user = getUserData();
    const token = getToken();

    useEffect(() => {
        setApiStatus(apiStatusConstants.inProgress);
        const fetchData = async () => {
            try {
                await getAllAssignedClients();
                if (user?.role === 'ADMIN') {
                    await getStaff();
                }
            } catch (error) {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        };
        const timeoutId = setTimeout(fetchData, 1000);
        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getStaff = async () => {
        setApiStatus(apiStatusConstants.inProgress);
        try {
            const response = await axios.get(`${domain.domain}/user/staff`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setStaff(response.data);
            setApiStatus(apiStatusConstants.success);
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    const getAllAssignedClients = async () => {
        setApiStatus(apiStatusConstants.inProgress);
        try {
            const unassignedClients = await axios.get(`${domain.domain}/user/unassigned-clients`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const allClientsResponse = await axios.get(`${domain.domain}/user`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (unassignedClients.status !== 200 || allClientsResponse.status !== 200) {
                throw new Error('Failed to fetch data.');
            }
            const allClients = allClientsResponse.data.filter((client) => client.role === 'CUSTOMER');
            setAllClients(allClients);

            const filteredClients = unassignedClients.data.filter((client) => client.role === 'CUSTOMER');
            setClients(filteredClients);

            setApiStatus(apiStatusConstants.success);
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };


    const calculateTotal = (clients, step) => {
        const total = clients.filter((client) => client.current_step === step);
        return total.length;
    };

    const data = {
        Scheduling: {
            title: "Scheduling",
            description: "Schedule appointments and meetings with clients.",
            total: calculateTotal(allClients, 'Scheduling'),
            icon: <FaCalendarAlt size={50} />,
            color: colorMapping["Scheduling"]
        },
        TaxInterview: {
            title: 'Tax Interview',
            description: 'Conduct tax interviews and gather necessary information.',
            total: calculateTotal(allClients, 'TaxInterview'),
            icon: <FaClock size={50} />,
            color: colorMapping['TaxInterview']
        },
        Documents: {
            title: 'Documents Collection',
            description: 'Collect and organize required tax documents from clients.',
            total: calculateTotal(allClients, 'Documents'),
            icon: <FaFileAlt size={50} />,
            color: colorMapping.Documents
        },
        TaxPreparation: {
            title: 'Tax Preparation',
            description: 'Prepare and calculate tax returns for clients.',
            total: calculateTotal(allClients, 'TaxPreparation'),
            icon: <FaTasks size={50} />,
            color: colorMapping['TaxPreparation']
        },
        Review: {
            title: 'Review',
            description: 'Review and verify tax returns for accuracy.',
            total: calculateTotal(allClients, 'Review'),
            icon: <FaCheck size={50} />,
            color: colorMapping.Review
        },
        Payments: {
            title: 'Payments Processing',
            description: 'Process and handle client payments related to tax services.',
            total: calculateTotal(allClients, 'Payments'),
            icon: <FaMoneyBillAlt size={50} />,
            color: colorMapping.Payments
        },
        ClientReview: {
            title: 'Client Review',
            description: 'Conduct client reviews and address any concerns or questions.',
            total: calculateTotal(allClients, 'ClientReview'),
            icon: <FaClipboardList size={50} />,
            color: colorMapping['ClientReview']
        },
        Filing: {
            title: 'Filing',
            description: 'Submit and file tax returns on behalf of clients.',
            total: calculateTotal(allClients, 'Filing'),
            icon: <FaFileAlt size={50} />,
            color: colorMapping.Filing
        },
    };

    const handleCardClick = async (key) => {

        setSelectedCard(key);
        setApiStatus(apiStatusConstants.inProgress);
        const response = await axios.get(`${domain.domain}/user/current-step/${key}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        if (response.status === 200) {
            setClients(response.data)
            setApiStatus(apiStatusConstants.success);
        }
        setTimeout(() => {
            const detailsContainer = document.getElementById('details-container');
            if (detailsContainer) {
                window.scrollTo({
                    top: detailsContainer.offsetTop,
                    behavior: 'smooth',
                });
            }
        }, 100);
        setAvailableSteps([])
    };

    const onDeleteClient = async (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete?');
        if (isConfirmed) {
            setApiStatus(apiStatusConstants.inProgress);
            try {
                await axios.delete(`${domain.domain}/user/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                getAllAssignedClients();
                setApiStatus(apiStatusConstants.success);
                showAlert({
                    title: 'Staff Deleted Successfully!',
                    text: 'The staff member has been deleted successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
            } catch (error) {
                if (error?.response?.data?.error) {
                    showAlert({
                        title: 'Error',
                        text: `${error.response.data.error}`,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                    setApiStatus(apiStatusConstants.success);
                } else {
                    setApiStatus(apiStatusConstants.failure);
                    setErrorMsg(error);
                }
            }
            getAllAssignedClients();
        }
    };

    const handleEditClick = (clientId) => {
        setIsEditModalOpen(!isEditModalOpen);
        setProfileId(clientId);
    };

    const handleEditModalClose = () => {
        setIsEditModalOpen(false);
    };

    const handleMoveToClick = (client) => {
        setSelectedClient(client);
        const currentStepIndex = dataOrder.indexOf(client.current_step);
        const stepsAfterCurrent = dataOrder.slice(currentStepIndex + 1);
        setAvailableSteps(stepsAfterCurrent);
    };

    const handleMoveToClickByStaff = (client) => {
        setShowSecretCodePopup(true);
        setSelectedClient(client);
        const currentStepIndex = staffDataOrder.indexOf(client.current_step);
        const stepsAfterCurrent = staffDataOrder.slice(currentStepIndex + 1);
        setAvailableSteps(stepsAfterCurrent);
    };

    const onChangeAccess = () => {
        setIsAuthenticated(!isAuthenticated);
    };

    const handleStepChange = async (selectedStep) => {
        setSelectedClient((prevClient) => ({
            ...prevClient,
            current_step: selectedStep,
        }));
        getAllAssignedClients();
        setApiStatus(apiStatusConstants.inProgress);

        try {
            const response = await axios.post(
                `${domain.domain}/user/current-step/${selectedClient.user_id}`,
                { current_step: selectedStep, user },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.data) {
                showAlert({
                    title: 'Client Moved Successfully!',
                    text: 'The client has been moved to the further step.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
                getAllAssignedClients();
                setAvailableSteps([])
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.error);
            }
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
            setAvailableSteps([])
        }
    };

    const getStaffLabel = (staff) => {
        return `${staff.first_name} ${staff.last_name}`;
    };

    const handleStaffChange = async (selectedStaff) => {
        try {
            setApiStatus(apiStatusConstants.inProgress);
            if (selectedRange < 1 || selectedRange > clients.length) {
                return setErrorMsg("Invalid range selected.");
            } else {
                setErrorMsg('')
            }
            const selectedClients = clients.slice(0, selectedRange);
            if (selectedClients.length < 1) {
                return setErrorMsg("There are no clients selected.");
            } else {
                setErrorMsg('')
            }
            if (Object.keys(selectedStaff).length !== 0) {
                await axios.post(
                    `${domain.domain}/staff-customer-assignments/assign-clients`,
                    { selectedStaff, selectedClients },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                showAlert({
                    title: 'Clients Assigned Successfully!',
                    text: 'The clients has been assigned to the staff.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
                getAllAssignedClients();
                setSelectedRange('');
            }
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    const onChangeRange = (e) => {
        setSelectedRange(e.target.value);
        setErrorMsg('');
    };

    const onAssignAll = async () => {
        try {
            setApiStatus(apiStatusConstants.inProgress);
            const res = await axios.post(
                `${domain.domain}/staff-customer-assignments/auto-assign-clients`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res) {
                showAlert({
                    title: 'Clients Assigned Successfully!',
                    text: 'The clients have been successfully assigned to staff members.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
                getStaff()
                getAllAssignedClients();
            }
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    const renderCardClients = () => (
        <ClientTable
            clients={clients}
            onDeleteClient={onDeleteClient}
            handleEditClick={handleEditClick}
            handleMoveToClick={userType === 'ADMIN' ? handleMoveToClick : handleMoveToClickByStaff}
            selectedClient={selectedClient}
            handleStepChange={handleStepChange}
            availableSteps={availableSteps}
            isEditModalOpen={isEditModalOpen}
            isAuthenticated={isAuthenticated}
            profileId={profileId}
            userType={userType}
            handleEditModalClose={handleEditModalClose} />

    );

    const renderHeader = () => {
        return (
            <ClientsHeaderContainer >
                <Heading>{data[selectedCard].title} :</Heading>
                {clients.length > 0 &&
                    <div>
                        <h6>Number of customer assign to a staff</h6>
                        <NoOfClientsAssignmentFilterContainer>
                            <Input placeholder="Enter a range" value={selectedRange} onChange={onChangeRange} />
                            <select
                                className='p-2'
                                onChange={(e) => handleStaffChange(JSON.parse(e.target.value))}
                                defaultValue="Select Staff"
                            >
                                <option value={JSON.stringify({})}>Select staff</option>
                                {staffList.map((staff) => (
                                    <option key={staff.user_id} value={JSON.stringify(staff)}>
                                        {getStaffLabel(staff)}
                                    </option>
                                ))}
                            </select>
                        </NoOfClientsAssignmentFilterContainer>
                        {errorMsg && <span className='text-danger'>{errorMsg}</span>}
                    </div>}
            </ClientsHeaderContainer>
        )
    }

    const renderSuccess = () => {
        return (
            <DashboardContainer>
                <Heading>Dashboard</Heading>
                <CardsContainer>
                    {Object.entries(data).map(([key, value]) => (
                        <SectionCard
                            key={key}
                            onClick={() => handleCardClick(key)}
                            className={selectedCard === key ? 'selected' : ''}
                            style={{
                                borderBottom: selectedCard === key ? `6px solid ${value.color}` : '',
                            }}
                        >
                            <DashboardItem title={value.title} >
                                <DashboardIcon style={{ color: value.color }}>{value.icon}</DashboardIcon>
                                <CardDetailsContainer>
                                    <CardHeading>{value.title}</CardHeading>
                                    <CardDescription>{value.description}</CardDescription>
                                    <p><strong>Total: </strong>{value.total}</p>
                                </CardDetailsContainer>
                            </DashboardItem>
                        </SectionCard>
                    ))}
                </CardsContainer>
                {userType === 'ADMIN' && clients.length > 0 && !selectedCard &&
                    <div>
                        <ClientsHeaderContainer>
                            <Heading>Unassigned Clients</Heading>
                            <button className='btn bg-success text-light' onClick={onAssignAll} title='Auto assign all to Scheduling'>Assign All</button>
                        </ClientsHeaderContainer>
                        {renderCardClients()}
                    </div>
                }
                {clients.length > 0 && selectedCard ?
                    <div>
                        {userType === 'ADMIN' ? renderHeader() :
                            <Heading>{data[selectedCard].title} :</Heading>
                        }
                        {renderCardClients()}
                    </div>
                    :
                    <div>
                        No clients
                    </div>
                }

                {showSecretCodePopup && (
                    <SecretCode
                        isOpen={showSecretCodePopup}
                        onRequestClose={() => setShowSecretCodePopup(false)}
                        handleOpenClick={() => setShowSecretCodePopup(true)}
                        onChangeAccess={onChangeAccess}
                        myCode={user.secret_code}
                        team={user.staff_team}
                        selectedCard={selectedCard}
                    />
                )}
            </DashboardContainer>
        )
    }

    const renderComponents = () => {
        switch (apiStatus) {
            case apiStatusConstants.inProgress:
                return <SweetLoading />;
            case apiStatusConstants.success:
                return renderSuccess();
            case apiStatusConstants.failure:
                return <FailureComponent errorMsg={errorMsg} fetchData={getAllAssignedClients} />;
            default:
                return null;
        }
    }

    return (
        renderComponents()
    )
}

export default Dashboard