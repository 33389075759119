// Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Components
import domain from '../domain/domain';
import EditModal from '../SweetPopup/sweetPopup';
import SweetLoading from '../SweetLoading/SweetLoading';

// Assets
import noClient from '../Assets/no-customers.png'

// Styled Components
import {
    ClientListContainer,
    Heading,
    NoClientContainer,
    TableContainer,
} from './StyledComponents';

import showAlert from '../SweetAlert/sweetalert';
import ClientTable from './clientTable';
import ClientFilter from './clientFilter';
import FailureComponent from '../FailureComponent/failureComponent';
import { getToken, getUserData } from '../StorageMechanism/storageMechanism';
import { Container } from '../AdminComponents/Staff/styledComponents';

const apiStatusConstants = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
};

const Clients = ({ userType }) => {

    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [assignmentClients, setAssignedClients] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [profileId, setProfileId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFilter, setFilterType] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial)
    const token = getToken();
    const user = getUserData();

    const navigate = useNavigate();

    const fetchClients = async () => {
        setApiStatus(apiStatusConstants.inProgress)
        try {
            const response = await axios.get(`${domain.domain}/user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data;
            if (response.status === 200) {
                const filteredData = data.filter((user) => user.role === 'CUSTOMER');
                setClients(filteredData);
                setFilteredClients(filteredData)
                setApiStatus(apiStatusConstants.success)
            }
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    const getAllAssignedClients = async () => {
        setApiStatus(apiStatusConstants.inProgress)
        try {
            const assignedClientsResponse = await axios.get(`${domain.domain}/staff-customer-assignments`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (assignedClientsResponse.status === 200) {
                const assignedClients = assignedClientsResponse.data;
                setAssignedClients(assignedClients);
                setApiStatus(apiStatusConstants.success)
            }

        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    useEffect(() => {
        if (user) {
            if (user.role === 'STAFF') {
                navigate('/staff/dashboard')
            } else if (user.role === 'CUSTOMER') {
                navigate('/user/dashboard')
            }
        } else {
            navigate('/')
        }
        getAllAssignedClients();
        fetchClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, navigate]);

    // Handle search term change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFilterChange = (filterType) => {
        setFilterType(filterType);

        let filteredData = [];

        if (filterType === 'assigned') {
            filteredData = clients.filter((client) =>
                assignmentClients.some((assignmentClient) => assignmentClient.client_id === client.user_id)
            );
        } else if (filterType === 'unassigned') {
            filteredData = clients.filter((client) =>
                !assignmentClients.some((assignmentClient) => assignmentClient.client_id === client.user_id)
            );
        } else {
            filteredData = clients;
        }
        setFilteredClients(filteredData);
    };

    // Handle edit button click
    const handleEditClick = () => {
        setIsEditModalOpen(!isEditModalOpen);
    };

    const handleEditModalClose = () => {
        setIsEditModalOpen(false);
    };

    const onSearch = () => {
        if (searchTerm) {
            const filteredData = clients.filter((user) =>
                user?.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user?.contact_number?.includes(searchTerm) ||
                user?.email_address?.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredClients(filteredData);
        } else {
            setFilteredClients(clients);
        }
    };

    const changeStatus = async () => {
        setApiStatus(apiStatusConstants.inProgress);

        try {
            const response = await axios.put(
                `${domain.domain}/dummy-users/status/${profileId}`,
                { status: 'unregistered' },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            fetchClients()

            if (response.status === 200) {
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
            }
        } catch (error) {
            if (error?.response?.data?.error) {
                showAlert({
                    title: 'Error',
                    text: `${error.response.data.error}`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                setErrorMsg(error);
            }
        }
    };

    // Handle staff deletion
    const onDeleteClient = async (id) => {
        const userConfirmed = window.confirm("Are you sure you want to delete?");
        if (userConfirmed) {
            setApiStatus(apiStatusConstants.inProgress);

            try {
                await axios.delete(`${domain.domain}/user/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                changeStatus()
                setApiStatus(apiStatusConstants.success);

                // Show success alert
                showAlert({
                    title: 'Client Deleted Successfully!',
                    text: 'The client has been deleted successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
                fetchClients()
            } catch (error) {
                if (error?.response?.data?.error) {
                    showAlert({
                        title: 'Error',
                        text: `${error.response.data.error}`,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                    setApiStatus(apiStatusConstants.success);
                } else {
                    setApiStatus(apiStatusConstants.failure);
                    setErrorMsg(error);
                }
            }
        }
    };


    const renderSuccess = () => {
        return (
            <ClientListContainer>
                <Heading>Clients</Heading>
                <TableContainer className='shadow'>
                    <ClientFilter
                        selectedFilter={selectedFilter}
                        handleFilterChange={handleFilterChange}
                        handleSearchChange={handleSearchChange}
                        onSearch={onSearch}
                        searchTerm={searchTerm}
                        userType={userType}
                    />
                    {filteredClients.length > 0 ?
                        <Container>

                            <ClientTable
                                clients={filteredClients}
                                onDeleteClient={onDeleteClient}
                                handleEditClick={handleEditClick}
                                setProfileId={setProfileId}
                                userType={userType}
                            />
                        </Container>
                        :
                        <NoClientContainer>
                            <img src={noClient} alt='img' className='img-fluid' />
                            <Heading>No Clients Registered</Heading>
                            <p>Oops! It seems there are no clients registered here.</p>
                        </NoClientContainer>}
                </TableContainer>

                <EditModal
                    isOpen={isEditModalOpen}
                    profileId={profileId}
                    onRequestClose={handleEditModalClose}
                    handleOpenClick={handleEditClick}
                    isEditable={true}
                />
            </ClientListContainer>
        )
    }


    const renderComponents = () => {
        switch (apiStatus) {
            case apiStatusConstants.failure:
                return <FailureComponent errorMsg={errorMsg} fetchData={fetchClients} />;
            case apiStatusConstants.inProgress:
                return <SweetLoading />;
            case apiStatusConstants.success:
                return renderSuccess();
            default:
                return null
        }
    }

    return (
        renderComponents()
    );
};

export default Clients;
