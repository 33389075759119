import { useEffect } from "react"
import Documents from "../../Documents/Documents"
import { getUserData } from "../../StorageMechanism/storageMechanism";
import { useNavigate } from "react-router-dom";

const ClientDocuments = () => {
    const user = getUserData()

    const role = user.role
    const navigate = useNavigate();

    useEffect(() => {
        if (role) {
            if (role === 'ADMIN') {
                navigate('/admin/dashboard');
            } else if (role === 'CUSTOMER') {
                navigate('/user/dashboard');
            }
        }
    })

    return (
        <Documents userType={'STAFF'} />
    )
}

export default ClientDocuments