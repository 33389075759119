import Clients from "../../Clients/Clients"
import { getUserData } from "../../StorageMechanism/storageMechanism"


const Client = () => {
    const user = getUserData()
    return(
        <Clients userType={user.role}/>
    )
}

export default Client