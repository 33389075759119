// ClientTable.jsx
import React from 'react';
import { MdDelete } from 'react-icons/md';
import EditModal from '../../SweetPopup/sweetPopup';
import { ExecuteButton, Td, TableContainer, Table, Th } from '../../Clients/StyledComponents';

const ClientTable = ({ clients, onDeleteClient, handleMoveToClick, handleEditClick, selectedClient, handleStepChange, availableSteps, isEditModalOpen, profileId, handleEditModalClose, isAuthenticated, userType }) => {

    return (
        <>
            <TableContainer className='shadow'>
                <Table>
                    <thead>
                        <tr>
                            <Th>ID</Th>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Phone</Th>
                            <Th>Current Step</Th>
                            <Th>Actions</Th>
                            <Th>Update</Th>
                            {userType === 'ADMIN' && <Th>Delete</Th>}
                        </tr>
                    </thead>
                    <tbody>
                        {clients ? (
                            clients.map(client => (
                                <tr key={client.user_id}>
                                    <Td>{client.user_id}</Td>
                                    <Td>{client.first_name}</Td>
                                    <Td>{client.email_address}</Td>
                                    <Td>{client.contact_number}</Td>
                                    <Td>{client.current_step ? client.current_step : 'Null'}</Td>
                                    <Td>
                                        <ExecuteButton onClick={() => handleEditClick(client.user_id)}>
                                            View Profile
                                        </ExecuteButton>
                                    </Td>
                                    <Td>
                                        {(isAuthenticated || userType === 'ADMIN') && selectedClient && selectedClient.user_id === client.user_id && availableSteps.length > 0 && (
                                            <select
                                                id="moveTo"
                                                value=""
                                                className='p-2'
                                                onChange={(e) => handleStepChange(e.target.value)}
                                            >
                                                <option value="" disabled>Select an option</option>
                                                {availableSteps.map((step) => (
                                                    <option key={step} value={step}>
                                                        {step}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                        <ExecuteButton onClick={() => handleMoveToClick(client)}>
                                            Move To
                                        </ExecuteButton>
                                    </Td>
                                    {userType === 'ADMIN' && (
                                        <Td>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <button className='btn text-danger' onClick={() => onDeleteClient(client.user_id)} style={{ gap: '10px' }} title='Delete client'> Delete <MdDelete size={25} /></button>
                                            </div>
                                        </Td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <Td colSpan={userType === 'ADMIN' ? 8 : 7}>No clients available</Td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <EditModal
                    isOpen={isEditModalOpen}
                    profileId={profileId}
                    onRequestClose={handleEditModalClose}
                    handleOpenClick={handleEditClick}
                    isEditable={true}
                />
            </TableContainer>
        </>
    );
};

export default ClientTable;
